import React, { PureComponent, useEffect, useState } from "react";
import { Spin } from "antd";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Label } from "recharts";
import { n, getDiasUteisNoMes } from "@util";
import "./StyledPanel.css";
import PageTitle from "components/PageTitle";

const MetaCorridaPanel = ({ data, mes, tipo, isDataAvailable = true }) => {
  const [dados, setDados] = useState(null);

  console.log('dataaaaa -> ', data);

  useEffect(() => {
    if (data) {
      setDados(transformarDados(data));
    }
  }, [data]);

  function transformarDados(dados) {
    const chavesDesejadas = ["vendido", "meta_atual"];

    return chavesDesejadas.map(chave => ({
      nome: chave === "vendido" ? "Vendido" : "Meta atual",
      dado: dados[chave],
      // dado: chave === "vendido" ? '2000000' : dados[chave],
    }));
  }

  const Chart = ({ isLoading, label, chartValue, width = 509, height = 195, percent, children, titleX, ...props }) => {
    const CustomizedLabel = ({ x, y, value, unit, width, height, ...props }) => {
      if (value === 0) {
        return (
          <text></text>
        )
      }

      const altura = y + height / 2;

      console.log('altura -> ', altura);

      const primeiro = altura === 35;

      const route = dados[0].dado > dados[1].dado ? require('img/running_green.svg') : require('img/running_red.svg');

      const CustomSVGRunning = ({ color }) =>
        <image
          href={route}
          x={x + width + 5}
          y={altura - 13}
          width="30"
          height="30"
          fill={color} />;

      const CustomSVGDollar = () =>
        <image
          href={require('img/dollar.svg')}
          x={x + width + 5}
          y={altura - 13}
          width="25"
          height="25" />;

      return (
        <g>
          {primeiro ? <CustomSVGRunning color={"#CC0000"} /> : <CustomSVGDollar />}
          <text
            x={x + width + 40}
            y={altura + (5)}
            fill="#222"
            textAnchor="start"
          >
            {unit ? unit + " " + n(value, 2) : n(value, 2)}
          </text>
        </g>
      )
    };

    const CustomBarShape = (props) => {
      const { x, y, width, height, nome } = props;

      let color = '#0F73B9';

      if (nome === 'Vendido') {
        color = dados[0].dado > dados[1].dado ? '#33cc33' : "#CC0000"
      }

      return <rect x={x} y={y} width={width} height={height} fill={color} />;
    };

    const customTick = (value) => {
      const index = diasUteisEscalados.indexOf(value);
      return index !== -1 ? diasUteis[index] : '';
    }

    class CustomizedAxisTick extends PureComponent {
      render() {
        const { x, y, payload } = this.props;
        const angle = -45;

        const dias = customTick(payload.value);

        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end">
              {dias}
            </text>
            <text
              transform={`rotate(${angle}, ${15}, ${20})`}
              x={0} y={0} dy={16} textAnchor="end">
              {n(payload.value, 2)}
            </text>
          </g>
        );
      }
    }

    const diasUteis = getDiasUteisNoMes(mes);
    let diaAtual;

    if (mes === new Date().getMonth() + 1) {
      diaAtual = new Date().getDate();
    } else {
      diaAtual = diasUteis[diasUteis.length - 1];
    }

    const ultimoDiaUtil = diasUteis[diasUteis.length - 1];

    console.log('ultimoDiaUtil -> ', ultimoDiaUtil);

    const estaNosUltimosDias = diaAtual >= (ultimoDiaUtil - 4);

    let diasExtras = 3;
    if (dados && dados.length > 0 && dados[0].dado > dados[1].dado) {
      diasExtras += Math.floor((dados[0].dado - dados[1].dado) / data.meta_diaria);
    }

    const diasUteisEscalados = diasUteis.map((_, index) => (index + 1) * data.meta_diaria);
    const diasUteisComGordura = [
      ...diasUteisEscalados,
      ...Array(diasExtras).fill().map((_, i) => (diasUteisEscalados.length + 1 + i) * data.meta_diaria),
    ];

    return (
      <Spin spinning={isLoading} wrapperClassName="custom-spin">
        {!!data ?
          <ComposedChart
            layout="vertical"
            width={window.innerWidth * 0.68}
            height={180}
            data={dados}
            margin={{
              top: 20,
              right: 20,
              bottom: 70,
              left: -20,
            }}
            barCategoryGap={2}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              type="number"
              dataKey="dado"
              tick={<CustomizedAxisTick />}
              ticks={diasUteisEscalados}
              domain={[0, diasUteisComGordura[diasUteisComGordura.length - 1]]} // Expande o domínio para incluir os dias extras
              tickFormatter={customTick}
            >
              <Label value={titleX} offset={5} angle={-45} position="left" />
            </XAxis>
            <YAxis dataKey="nome" type="category" scale="band" width={100} />
            <Bar
              dataKey="dado"
              label={<CustomizedLabel />}
              shape={<CustomBarShape />}
              minPointSize={5}
            />
          </ComposedChart>
          : <div style={{
            width: 480,
            height: 280,
            top: 40,
            right: 55,
            left: 55,
            bottom: 20,
          }} />
        }
      </Spin>
    );
  };

  return (
    <div>
      <h2 className="text-center">Evolução de Venda - {tipo === "VOL" ? "Volume" : "Faturamento"}</h2>
      {data && isDataAvailable && (
        <Chart isLoading={false} title="Venda Mix" keyLabel="mes" unit="" titleX="Em dias úteis" />
      )}
    </div>
  );
};

export default MetaCorridaPanel;